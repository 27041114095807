const locales = [
    {
        "label": "English",
        "value": "en-us"
    },
    {
        "label": "Deutsch",
        "value": "de-de"
    },
    {
        "label": "日本語",
        "value": "ja-jp"
    },
    {
        "label": "中文",
        "value": "zh-cn"
    }
];

export default locales;