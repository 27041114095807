/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFormDefinition = /* GraphQL */ `
  mutation CreateFormDefinition(
    $createFormDefinitionInput: CreateFormDefinitionInput!
  ) {
    createFormDefinition(
      createFormDefinitionInput: $createFormDefinitionInput
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        language
        formName
        displayTitle
        description
        version
        versionComment
        status
        formTemplateType
        formTemplate
        createdDateTime
        createdBy
        helpContentLink
        extensions {
          id
          type
          name
          content {
            key
            value
          }
        }
        bindle
        bindleResourceId
        timeToEditFormSubmissions
        allowDrafts
      }
    }
  }
`;
export const submitForm = /* GraphQL */ `
  mutation SubmitForm($submitFormInput: SubmitFormInput!) {
    submitForm(submitFormInput: $submitFormInput) {
      statusCode
      message
      body {
        id
        application
        tenantId
        submitterEntityId
        responses
        formDefinitionId
        formDefinitionName
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        formSubmissionStatus
        targetEntityId
        createdDateTime
        lastUpdatedDateTime
        sharedWith {
          entityId
        }
      }
    }
  }
`;
export const updateFormSubmission = /* GraphQL */ `
  mutation UpdateFormSubmission(
    $updateFormSubmissionInput: UpdateFormSubmissionInput
  ) {
    updateFormSubmission(
      updateFormSubmissionInput: $updateFormSubmissionInput
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        submitterEntityId
        responses
        formDefinitionId
        formDefinitionName
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        formSubmissionStatus
        targetEntityId
        createdDateTime
        lastUpdatedDateTime
        sharedWith {
          entityId
        }
      }
    }
  }
`;
export const shareFormSubmission = /* GraphQL */ `
  mutation ShareFormSubmission(
    $shareFormSubmissionInput: ShareFormSubmissionInput!
  ) {
    shareFormSubmission(shareFormSubmissionInput: $shareFormSubmissionInput) {
      statusCode
      message
      entityId
      formSubmissionId
      body {
        formSubmissionId
        entityIds
      }
    }
  }
`;
export const unshareFormSubmission = /* GraphQL */ `
  mutation UnshareFormSubmission(
    $shareFormSubmissionInput: ShareFormSubmissionInput!
  ) {
    unshareFormSubmission(shareFormSubmissionInput: $shareFormSubmissionInput) {
      statusCode
      message
      entityId
      formSubmissionId
      body {
        formSubmissionId
        entityIds
      }
    }
  }
`;
