import { API, Auth, Storage } from "aws-amplify";
import { BUCKETS_CONFIG } from "src/components/wrappers/BucketsConfig";
import { AmplifyConfigType, AmplifyStageConfigMapping } from "src/components/wrappers/ServiceWrapperFunction";

const Cookies = require('js-cookie');

export let API_NAME: string;
export let BUCKET: string;
export let REGION: string;
export let STAGE: string;
export let IS_BETA: boolean;

async function getConfig() {
    return AmplifyStageConfigMapping.get("prod-NA");
}

async function isChangingAmplifyConfig(_serviceName?: AmplifyConfigType) {
    console.log('service name on cookie change', _serviceName);
    const service = _serviceName || AmplifyConfigType.ADMIN;
    const storedCookie = Cookies.get('ActiveAmplifyService');
    Cookies.set('ActiveAmplifyService', service);
    console.log('cookie', storedCookie);
    if (storedCookie && storedCookie !== service) {
        console.log('is changing amplify config');
        return true;
    }
    return false;
}

async function setupAWSConfig(amplifyConfigType: AmplifyConfigType) {
    const CONFIG = await getConfig();

    if (amplifyConfigType === AmplifyConfigType.FORMS) {
        await setupAWSConfigForms(CONFIG);
    } else {
        await setupAWSConfigAdmin(CONFIG);
    }
}

/**
 * Sets up the AWS config for the specified Amplify config type.
 * @param amplifyConfigType The Amplify config type to set up.
 * @param skipRefresh Whether to skip refreshing the page to load the new config.
 */
export async function setupAWSConfigForService(amplifyConfigType: AmplifyConfigType, skipRefresh?: boolean) {
    await setupAWSConfig(amplifyConfigType);
    if(!skipRefresh) {
        const changingConfig = await isChangingAmplifyConfig(amplifyConfigType);
        if (changingConfig) {
            window.location.reload();
        }
        return !changingConfig;
    }
    return true;
}

export async function setupAWSConfigAdmin(CONFIG) {
    const {admin: {API_ENDPOINT, USER_POOL_ID, USER_POOL_WEB_CLIENT_ID, COGNITO_DOMAIN, IDENTITY_POOL_ID}} = CONFIG;

    API_NAME = CONFIG["admin"]["API_NAME"];
    BUCKET = CONFIG["admin"]["BUCKET"];
    REGION = CONFIG["admin"]["REGION"];
    STAGE = CONFIG["admin"]["STAGE"];
    IS_BETA = CONFIG["admin"]["IS_BETA"];

    // tslint:disable-next-line:no-console
    console.log(`Setting up amplify for: ${API_NAME}, beta: ${IS_BETA}`,);

    const callbackUrl = window.location.origin;

    await Auth.configure({
        identityPoolId: IDENTITY_POOL_ID,
        region: "us-east-1",
        userPoolId: USER_POOL_ID,
        userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: true,
        oauth: {
            domain: COGNITO_DOMAIN,
            redirectSignIn: callbackUrl,
            redirectSignOut: callbackUrl,
            scope: ["openid"], // important
            responseType: "code"
        }
    });

    await API.configure({
        endpoints: [
            {
                name: API_NAME,
                endpoint: `${API_ENDPOINT}/prod/`,
                region: "us-east-1"
            }
        ]
    });
    IS_BETA ? await setStorageToBeta() : await setStorageToProd();
}

export async function setStorageToBeta() {
    Storage.configure({
        AWSS3: {
            bucket: BUCKETS_CONFIG.BETA_BUCKET,
            region: BUCKETS_CONFIG.BETA_BUCKET_REGION
        },
        level: "public"
    });
    return true;
}

export async function setStorageToProd() {
    Storage.configure({
        AWSS3: {
            bucket: BUCKETS_CONFIG.PROD_BUCKET,
            region: BUCKETS_CONFIG.PROD_BUCKET_REGION
        },
        level: "public"
    });
    return true;
}

export async function getUserInfo(): Promise<any> {
    let userInfo = null;
    try {
        await Auth.currentAuthenticatedUser().then((user) => {
            userInfo = user.getSignInUserSession().getIdToken().decodePayload();
        });
    } catch (e) {
        console.log(e);
    }
    return userInfo;
}

export async function buildAPIHeader() {
    return {
        headers: {
            Authorization: `Bearer ${await getJWTToken()}`
        }
    };
}

export async function setupAWSConfigForms(CONFIG) {

    if (!CONFIG.forms) {
        throw new Error('No forms config found');
    }

    const {
        API_NAME,
        IS_BETA,
        API_CONFIG,
        AUTH_CONFIG
    } = CONFIG.forms;

    // tslint:disable-next-line:no-console
    console.log(`Setting up amplify for: ${API_NAME}, beta: ${IS_BETA}`,);

    const callbackUrl = window.location.origin;


    const authenticationOptions = AUTH_CONFIG;
    // Need to set redirect urls for cognito auth on runtime
    authenticationOptions.oauth.redirectSignIn = callbackUrl;
    authenticationOptions.oauth.redirectSignOut = callbackUrl;

    // Set up Amplify
    await Auth.configure(authenticationOptions);
    await API.configure({
        ...API_CONFIG,
        graphql_headers: async () => ({
            idtoken: await getJWTToken(),
        })
    });

}

export async function getJWTToken() {
    const session = await Auth.currentSession();
    console.log("Setting JWT Token", session.getIdToken().getJwtToken());
    return session.getIdToken().getJwtToken();
}
