import { AxiomAdminTeam } from "src/components/authorization/AxiomAdminTeam";

export interface User {
    isMemberOfTeam: (team: AxiomAdminTeam) => boolean;
    getLogin: () => string;
}

export class UserWrapper implements User {

    private readonly user: any;

    constructor(user: any) {
        this.user = user;
    }

    isMemberOfTeam(team: AxiomAdminTeam): boolean {
        return this.user['custom:groups']?.includes(team);
    }

    getLogin(): string {
        return this.user['identities'][0]['userId'];
    }
}

export const createMockUser = (login: string, groups: string[]): User => (
    new UserWrapper({
        identities: [{ userId: login }],
        'custom:groups': groups
    })
);