/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getFormDefinition = /* GraphQL */ `
  query GetFormDefinition($getFormDefinitionInput: GetFormDefinitionInput!) {
    getFormDefinition(getFormDefinitionInput: $getFormDefinitionInput) {
      statusCode
      message
      body {
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        isEditableByUser
      }
    }
  }
`;
export const getLatestFormDefinition = /* GraphQL */ `
  query GetLatestFormDefinition(
    $getLatestFormDefinitionInput: GetLatestFormDefinitionInput!
  ) {
    getLatestFormDefinition(
      getLatestFormDefinitionInput: $getLatestFormDefinitionInput
    ) {
      statusCode
      message
      body {
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        isEditableByUser
      }
    }
  }
`;
export const getFormDefinitionsByFormName = /* GraphQL */ `
  query GetFormDefinitionsByFormName(
    $getFormDefinitionsByFormNameInput: GetFormDefinitionsByFormNameInput!
  ) {
    getFormDefinitionsByFormName(
      getFormDefinitionsByFormNameInput: $getFormDefinitionsByFormNameInput
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        language
        formName
        displayTitle
        description
        version
        versionComment
        status
        formTemplateType
        formTemplate
        createdDateTime
        createdBy
        helpContentLink
        extensions {
          id
          type
          name
          content {
            key
            value
          }
        }
        bindle
        bindleResourceId
        timeToEditFormSubmissions
        allowDrafts
      }
    }
  }
`;
export const getFormSubmissionsBySubmitter = /* GraphQL */ `
  query GetFormSubmissionsBySubmitter(
    $getFormSubmissionsBySubmitterInput: GetFormSubmissionsBySubmitterInput!
    $filter: FormSubmissionFilter
  ) {
    getFormSubmissionsBySubmitter(
      getFormSubmissionsBySubmitterInput: $getFormSubmissionsBySubmitterInput
      filter: $filter
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        submitterEntityId
        responses
        formDefinitionId
        formDefinitionName
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        formSubmissionStatus
        targetEntityId
        createdDateTime
        lastUpdatedDateTime
        sharedWith {
          entityId
        }
      }
    }
  }
`;
export const getFormSubmissionById = /* GraphQL */ `
  query GetFormSubmissionById(
    $getFormSubmissionByIdInput: GetFormSubmissionByIdInput!
  ) {
    getFormSubmissionById(
      getFormSubmissionByIdInput: $getFormSubmissionByIdInput
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        submitterEntityId
        responses
        formDefinitionId
        formDefinitionName
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        formSubmissionStatus
        targetEntityId
        createdDateTime
        lastUpdatedDateTime
        sharedWith {
          entityId
        }
      }
    }
  }
`;
export const getFormSubmissionsSharedWith = /* GraphQL */ `
  query GetFormSubmissionsSharedWith(
    $getFormSubmissionSharedWithInput: EntityInput!
    $filter: FormSubmissionFilter
  ) {
    getFormSubmissionsSharedWith(
      getFormSubmissionSharedWithInput: $getFormSubmissionSharedWithInput
      filter: $filter
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        submitterEntityId
        responses
        formDefinitionId
        formDefinitionName
        formDefinition {
          id
          application
          tenantId
          language
          formName
          displayTitle
          description
          version
          versionComment
          status
          formTemplateType
          formTemplate
          createdDateTime
          createdBy
          helpContentLink
          extensions {
            id
            type
            name
            content {
              key
              value
            }
          }
          bindle
          bindleResourceId
          timeToEditFormSubmissions
          allowDrafts
        }
        formSubmissionStatus
        targetEntityId
        createdDateTime
        lastUpdatedDateTime
        sharedWith {
          entityId
        }
      }
    }
  }
`;
export const listAvailableFormDefinitions = /* GraphQL */ `
  query ListAvailableFormDefinitions(
    $listAvailableFormDefinitionsInput: ListAvailableFormDefinitionsInput!
    $filter: ListAvailableFormDefinitionsFilter
  ) {
    listAvailableFormDefinitions(
      listAvailableFormDefinitionsInput: $listAvailableFormDefinitionsInput
      filter: $filter
    ) {
      statusCode
      message
      body {
        id
        application
        tenantId
        language
        formName
        displayTitle
        description
        version
        versionComment
        status
        formTemplateType
        formTemplate
        createdDateTime
        createdBy
        helpContentLink
        extensions {
          id
          type
          name
          content {
            key
            value
          }
        }
        bindle
        bindleResourceId
        timeToEditFormSubmissions
        allowDrafts
      }
    }
  }
`;
