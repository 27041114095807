import { setupAWSConfigForService } from "src/aws-config";
import { AmplifyConfig } from "./AmplifyConfig";


/**
 * - What is going on here?
 * This is a wrapper function that will be used to wrap all the functions in X service.
 * - Why?
 * Because we need to be able to switch between service auth configurations, since we are using multiple Amplify configurations.
 * - How?
 * We will use a cookie to store the active service, and when we switch services, we will reload the page. This is because of implementation
 * details of Amplify, where you cannot have multiple configurations active at the same time. This is a limitation of Amplify, and not a design
 * decision.
 * - What is this function doing then?
 * Basically, this function is a wrapper for the function that we want to wrap. It will check that the current active Amplify configuration is the
 * one the function is expecting, and if it is not, it will reload the page. If it is, it will call the function.
 * - Why not just call setupAWSConfigForService() in the function?
 * Because it's ugly.
 * - How can we stop using this?
 * When Amplify supports multiple configurations at the same time, we can remove this.
 * - How do we use this?
 * You can see an example of how to use this in Image.service.ts, creating a 'call' function that will be used to wrap all the functions in the
 * ImageService. ImageService uses the 'admin' amplify configuration, so we pass 'admin' to the ServiceWrapperFunction.
 * @param amplifyConfig
 * @constructor
 */
export const ServiceWrapperFunction = (amplifyConfig: AmplifyConfigType): Function => {
    return <T extends (...args: any[]) => any>(func: T): T =>
        <T>(async (...args: any[]) => {
            if (await setupAWSConfigForService(amplifyConfig)) {
                return await func(...args);
            }
        });
};

// service type Enum
export enum AmplifyConfigType {
    ADMIN = 'admin',
    FORMS = 'forms',
}

const betaNAAmplifyConfig: AmplifyConfig = {
    admin: {
        API_NAME: "AxiomAdmin",
        API_ENDPOINT: "https://x4u68ymx4i.execute-api.us-west-2.amazonaws.com",
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_OqZEJBrkl",
        USER_POOL_WEB_CLIENT_ID: "4ilojaupsto65e6t0kgdh0cckc",
        COGNITO_DOMAIN: "axiom-admin-beta.auth.us-west-2.amazoncognito.com",
        IDENTITY_POOL_ID: "us-west-2:e0011bbd-a643-4986-8183-abbc845b0d3c",
        STAGE: "beta",
        IS_BETA: true
    },
    forms: {
        API_NAME: "AxiomForms",
        IS_BETA: true,
        API_CONFIG: {
            aws_appsync_graphqlEndpoint: "https://ijsdpe3epbhdxb5jy7p64xjrnq.appsync-api.us-west-2.amazonaws.com/graphql",
            aws_appsync_region: "us-west-2",
            aws_appsync_authenticationType: "AWS_IAM"
        },
        AUTH_CONFIG: {
            identityPoolId: "us-west-2:71ebac58-7f92-45e3-96e2-a25b3aefcf3a",
            region: "us-west-2",
            userPoolId: "us-west-2_OqZEJBrkl",
            userPoolWebClientId: "4ilojaupsto65e6t0kgdh0cckc",
            mandatorySignIn: true,
            oauth: {
                domain: "axiom-admin-beta.auth.us-west-2.amazoncognito.com",
                scope: ["openid"],
                responseType: "code",
                redirectSignIn: "",
                redirectSignOut: ""
            }
        }
    }
};

const gammaNAAmplifyConfig: AmplifyConfig = {
    admin: {
        API_NAME: "AxiomAdmin",
        API_ENDPOINT: "https://tndck6gtpf.execute-api.us-east-1.amazonaws.com",
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_6O4YDAuB3",
        USER_POOL_WEB_CLIENT_ID: "4jmusglsvpe4qh27jeiqtpha7p",
        COGNITO_DOMAIN: "axiom-admin-gamma.auth.us-east-1.amazoncognito.com",
        IDENTITY_POOL_ID: "us-east-1:c6595d43-73e2-43c5-896a-b8ab41a8236f",
        STAGE: "gamma",
        IS_BETA: false
    },
    forms: {
        API_NAME: "AxiomForms",
        IS_BETA: false,
        API_CONFIG: {
            aws_appsync_graphqlEndpoint: "https://zio46evuenedbdcj57b2jgyjju.appsync-api.us-east-1.amazonaws.com/graphql",
            aws_appsync_region: "us-east-1",
            aws_appsync_authenticationType: "AWS_IAM"
        },
        AUTH_CONFIG: {
            identityPoolId: "us-east-1:171a080b-bf95-4c2d-a313-49a25871a08a",
            region: "us-east-1",
            userPoolId: "us-east-1_6O4YDAuB3",
            userPoolWebClientId: "4jmusglsvpe4qh27jeiqtpha7p",
            mandatorySignIn: true,
            oauth: {
                domain: "axiom-admin-gamma.auth.us-east-1.amazoncognito.com",
                scope: ["openid"],
                responseType: "code",
                redirectSignIn: "",
                redirectSignOut: ""
            }
        }
    }
};

const prodNAAmplifyConfig: AmplifyConfig = {
    admin: {
        API_NAME: "AxiomAdmin",
        API_ENDPOINT: "https://mqf6r0qcsl.execute-api.us-east-1.amazonaws.com",
        REGION: "us-east-1",
        USER_POOL_ID: "us-east-1_OPxQ7n9lx",
        USER_POOL_WEB_CLIENT_ID: "3kc43tbq1kkh2jaks9n0okj30s",
        COGNITO_DOMAIN: "axiom-admin-prod-na.auth.us-east-1.amazoncognito.com",
        IDENTITY_POOL_ID: "us-east-1:3c443c5e-b73f-42b1-a9df-dcd9757203a7",
        STAGE: "prod",
        IS_BETA: false
    },
    forms: {
        API_NAME: "AxiomForms",
        IS_BETA: false,
        API_CONFIG: {
            aws_appsync_graphqlEndpoint: "https://rq7eshp25zdtzbjye3dfm4f7ey.appsync-api.us-east-1.amazonaws.com/graphql",
            aws_appsync_region: "us-east-1",
            aws_appsync_authenticationType: "AWS_IAM"
        },
        AUTH_CONFIG: {
            identityPoolId: "us-east-1:7e0d8fae-790c-4e60-9263-9f6e1cd582e9",
            region: "us-east-1",
            userPoolId: "us-east-1_OPxQ7n9lx",
            userPoolWebClientId: "3kc43tbq1kkh2jaks9n0okj30s",
            mandatorySignIn: true,
            oauth: {
                domain: "axiom-admin-prod-na.auth.us-east-1.amazoncognito.com",
                scope: ["openid"],
                responseType: "code",
                redirectSignIn: "",
                redirectSignOut: ""
            }
        }
    }
};
export const AmplifyStageConfigMapping = new Map<string, AmplifyConfig> ([
        ["beta-NA", betaNAAmplifyConfig],
        ["gamma-NA", gammaNAAmplifyConfig],
        ["prod-NA", prodNAAmplifyConfig]
    ]
);