import {
    BreadcrumbGroup
} from '@amzn/awsui-components-react/polaris';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BreadcrumbGroupProps } from "@amzn/awsui-components-react/polaris";

const AppBreadcrumbs = ({ items }: { items: BreadcrumbGroupProps.Item[] }) => {
    let navigate = useNavigate();
    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            navigate(ev.detail.href);
        }
    };

    return <BreadcrumbGroup items={ items } ariaLabel="Breadcrumbs" onFollow={onFollowHandler}/>;
};

export default AppBreadcrumbs;